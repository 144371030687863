<template>
    <page-title
        icon="bi-file-spreadsheet"
        title="エクスポート定義編集"
    >
        <button-back
            :to="{name: 'ExportLayoutList'}"
            text="エクスポート定義TOPへ"
        ></button-back>
    </page-title>

    <inline-loader v-if="loading"></inline-loader>
    <template v-else>
        <section class="section">
            <form @submit.prevent="save">
                <div class="form-group col-md-6 mb-3">
                    <label>定義名</label>
                    <form-input
                        v-model="export_layout.export_layout_name"
                        :required="true"
                        maxlength="20"
                    ></form-input>
                </div>
                <div class="form-group col-md-6 mb-3">
                    <label>定義タイプ</label>
                    <form-select
                        v-model="export_layout.layout_type"
                        :options="layout_types"
                        :required="true"
                        empty_option="-- 選択 --"
                    ></form-select>
                </div>

                <div class="mb-3" v-if="FieldControlService.is_shown_product_group(export_layout.layout_type)">
                    <h4>製品グループ情報</h4>
                    <div class="form-group col mt-2 ms-3">
                        <form-checkboxes
                            v-model="fields"
                            :options="product_group_fields"
                            :required="true"
                            class="flex-column"
                        ></form-checkboxes>
                    </div>
                </div>
                <div class="mb-3" v-if="FieldControlService.is_shown_product_subgroup(export_layout.layout_type)">
                    <h4>製品サブグループ情報</h4>
                    <div class="form-group col mt-2 ms-3">
                        <form-checkboxes
                            v-model="fields"
                            :options="product_subgroup_fields"
                            :required="true"
                            class="flex-column"
                        ></form-checkboxes>
                    </div>
                </div>
                <div class="mb-3" v-if="FieldControlService.is_shown_product(export_layout.layout_type)">
                    <h4>製品情報</h4>
                    <div class="form-group col mt-2 ms-3">
                        <form-checkboxes
                            v-model="fields"
                            :options="product_fields"
                            :required="true"
                            class="flex-column"
                        ></form-checkboxes>
                    </div>
                </div>
                <div class="mb-3" v-if="FieldControlService.is_shown_product_retailer(export_layout.layout_type)">
                    <h4>商社別情報</h4>
                    <div class="form-group col mt-2 ms-3">
                        <form-checkboxes
                            v-model="fields"
                            :options="product_retailer_fields"
                            :required="true"
                            class="flex-column"
                        ></form-checkboxes>
                    </div>
                </div>
                <div class="mb-3" v-if="FieldControlService.is_shown_parent_product(export_layout.layout_type)">
                    <h4>親製品情報</h4>
                    <div class="form-group col mt-2 ms-3">
                        <form-checkboxes
                            v-model="fields"
                            :options="parent_product_fields"
                            :required="true"
                            class="flex-column"
                        ></form-checkboxes>
                    </div>
                </div>

                <p class="notice"><span class="checkbox--after--red">※</span> : 製作情報閲覧権限を持っていない場合、選択するとエクスポートできません。</p>

                <div class="d-flex justify-content-between">
                    <button-exec-update
                        size="btn-lg"
                    ></button-exec-update>
                    <button-exec-delete
                        size="btn-lg"
                        @click="$refs.confirm_remove.show()"
                    ></button-exec-delete>
                </div>
            </form>
        </section>
    </template>

    <confirm-dialog ref="confirm_remove" @ok="remove()">
        <p>削除してもよろしいですか？</p>
    </confirm-dialog>
</template>

<script>
import ExportLayout from '@/models/entities/export-layout';
import ExportLayoutDetail from '@/models/entities/export-layout-detail';
import FieldControlService from '@/models/services/field-control-service.js';
import LayoutType from '@/models/enums/layout-type';
import ConfirmDialog from '@/components/tools/ConfirmDialog.vue';
import InlineLoader from '@/components/tools/InlineLoader.vue';
import PageTitle from '@/components/PageTitle.vue';
import ButtonExecDelete from '@/components/buttons/ButtonExecDelete';
import ButtonExecUpdate from '@/components/buttons/ButtonExecUpdate';
import ButtonBack from '@/components/buttons/ButtonBack';
import FormCheckboxes from '@/components/forms/FormCheckboxes';
import FormInput from '@/components/forms/FormInput';
import FormSelect from '@/components/forms/FormSelect';

export default {
    name: 'ExportLayoutEdit',
    components: {
        ConfirmDialog,
        InlineLoader,
        PageTitle,
        ButtonExecDelete,
        ButtonExecUpdate,
        ButtonBack,
        FormCheckboxes,
        FormInput,
        FormSelect,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage'
    ],
    data() {
        return {
            //部分ローダー
            loading: true,

            //対象エクスポート定義ID
            export_layout_id: parseInt(this.$route.params.id, 10),

            // 送信する値
            export_layout: new ExportLayout(),

            // 選択肢
            layout_types: LayoutType.options(),

            // 定義の項目(製品グループ情報)
            product_group_fields: FieldControlService.optionsOfProductGroup(),
            // 定義の項目(製品サブグループ情報)
            product_subgroup_fields: FieldControlService.optionsOfProductSubGroup(),
            // 定義の項目(製品情報)
            product_fields: FieldControlService.optionsOfProduct(),
            // 定義の項目(商社別情報)
            product_retailer_fields: FieldControlService.optionsOfProductRetailer(),
            // 定義の項目(親製品情報)
            parent_product_fields: FieldControlService.optionsOfParentProduct(),

            //Enums
            LayoutType: LayoutType,
            //service
            FieldControlService: FieldControlService,
        }
    },
    mounted() {
        this.$http.get(`/export-layout/${this.export_layout_id}`)
        .then(response => {
            this.export_layout = new ExportLayout(response.data);
        })
        .finally(() => {
            this.loading = false;
        });
    },
    computed: {
        fields: {
            get() {
                return this.export_layout.details.map((detail) => {return detail.field_key;});
            },
            set(field_keys) {
                this.export_layout.details.splice(0);

                if (field_keys.length) {
                    let fields = FieldControlService.getFields(field_keys);
                    fields.forEach((field, index) => {
                        let detail = new ExportLayoutDetail();

                        detail.field_key = field.field_key;
                        detail.field_header = field.field_name;
                        detail.field_sequence = index + 1;

                        this.export_layout.details.push(detail);
                    });
                }
            }
        }
    },
    methods: {
        save() {
            this.startScreenLoading();
            this.$http.put(`/export-layout/${this.export_layout_id}`, this.export_layout)
            .then(() => {
                this.showMessage('更新しました');
                this.$router.push({name: 'ExportLayoutList'});
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        remove() {
            this.startScreenLoading();

            this.$http.delete(`/export-layout/${this.export_layout_id}`)
            .then(() => {
                this.showMessage('削除しました');
                this.$router.push({name: 'ExportLayoutList'});
            })
            .finally(() => {
                this.endScreenLoading();
            });
        }
    }
}
</script>

<style scoped>
    .notice {font-size: 80%; color: #333;}
    .checkbox--after--red {color: red;}
</style>
